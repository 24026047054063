import React, { useEffect, useState } from "react";

import moment from "moment";
import { combineLatest, defer, EMPTY, of, Timestamp } from "rxjs";
import { delay, expand, map, switchMap, tap, timestamp } from "rxjs/operators";

import { useObservedValue } from "../hooks/observable";
import LoadingIcon from "../icons/loading";

import { AppointmentBounce, AppointmentBouncesPayload } from "~/src/types";
import { getAppointmentsBounces$, postContactedAfterBounce } from "~/src/utils/api-examedi";
import { shortenServices } from "~/src/utils/services";
import { parsePhone } from "~/src/utils/text";

type TableBouncesProps = {
  interval: number;
  tallerTable: boolean;
};

type Targeted = {
  target?: boolean;
};

type TargetEmitted<T> = Timestamp<T> & Targeted;

function TableBounces({ interval, tallerTable }: TableBouncesProps): JSX.Element {
  const [data, setData] = useState<AppointmentBounce[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [updatedAt, setUpdatedAt] = useState<Date | null>(null);
  const [allowedToFetch, setAllowedToFetch] = useState<boolean>(true);
  const [forcedFetch, setForcedFetch] = useState<number | undefined>();

  /**
   * Observable to allowedtoFetch param, emits when the value changes
   */
  const allowedToFetchObservable$ = useObservedValue<boolean>(allowedToFetch).pipe(timestamp());
  /**
   * Observable to forcedFetch param, emits when the value changes
   */
  const forcedFetchObservable$ = useObservedValue<number | undefined>(forcedFetch).pipe(timestamp());

  useEffect(() => {
    /**
     * Returns an Observable that is subscribed and then emits the result from getting data from the appointment bounces endpoint
     * Sets the loading value before and after getting data
     * Sets the updatedAt value when the result is ready
     */
    const appointmentFetch$ = defer(() => {
      setLoading(true);
      return getAppointmentsBounces$().pipe(
        tap({
          next: () => {
            setUpdatedAt(new Date());
            setLoading(false);
          },
        }),
      );
    });

    /**
     * Returns a stream that combine the lastest values emitted from the ``allowedToFetchObservable$`` and ``forcedFetchObservable$``
     * adds a ``target`` param to differentiate which is the one that generates the emission
     */
    const markedLatestObservable$ = combineLatest([allowedToFetchObservable$, forcedFetchObservable$]).pipe(
      map((value: [TargetEmitted<boolean>, TargetEmitted<number | undefined>]) => {
        const timestampArray = value.map((x) => x.timestamp);
        const indexOfMostRecentValue = timestampArray.indexOf(Math.max(...timestampArray));
        value[indexOfMostRecentValue].target = true;
        return value;
      }),
    );

    /**
     * Returns a steam that
     */
    const source$ = markedLatestObservable$.pipe(
      /**
       * Cancel the previous emission and the returned observable is subscribed
       */
      switchMap(([allowedToFetch_, forcedSrc_]) => {
        /**
         * If the emission was generated by the forced source & the recursive calls function is disabled
         */
        if (forcedSrc_.target && !allowedToFetch_.value) {
          return appointmentFetch$.pipe(
            map((result: AppointmentBouncesPayload): AppointmentBounce[] => result?.data || []),
          );
        }
        /**
         * If the recursive calls function is enabled
         */
        if (allowedToFetch_.value) {
          return appointmentFetch$.pipe(
            /**
             * Recursively call provided function
             */
            expand(() => {
              /**
               * Returns an observable that delay the emmited value
               */
              return of(null).pipe(
                delay(interval),
                /**
                 * Cancel the previous emission and the returned observable is subscribed
                 */
                switchMap(() => appointmentFetch$),
              );
            }),
            map((result: AppointmentBouncesPayload): AppointmentBounce[] => result?.data || []),
          );
        } else {
          /**
           * if the recursive calls function is disabled
           */
          return EMPTY;
        }
      }),
    );

    const subscription = source$.subscribe({
      next: (result: AppointmentBounce[]) => {
        setData(result);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forceRefreshAppointmentsBounces = () => {
    setForcedFetch(Date.now());
  };

  return (
    <div className="bg-white shadow rounded-lg p-4">
      <div className="mb-4 min-w-full flex items-center justify-between">
        <h3 className="text-xl font-bold text-gray-900 mb-2 inline">
          Latest Bounces
          {updatedAt && (
            <span className="font-normal text-base"> (updated at {moment(updatedAt).format("HH:mm:ss")})</span>
          )}
        </h3>
        {loading && <LoadingIcon />}
        {!loading && allowedToFetch && (
          <span className="cursor-pointer" onClick={() => setAllowedToFetch(false)}>
            🔴
          </span>
        )}
        {!loading && !allowedToFetch && (
          <span className="cursor-pointer" onClick={() => setAllowedToFetch(true)}>
            🔘
          </span>
        )}
      </div>
      <div
        className={tallerTable ? "overflow-scroll no-scrollbar max-h-140" : "overflow-scroll no-scrollbar max-h-120"}
      >
        <div className="align-middle inline-block min-w-full">
          <div className="shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User
                  </th>
                  <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Contact
                  </th>
                  <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Contacted
                  </th>
                  <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Scheduled at
                  </th>
                  <th scope="col" className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Services
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data &&
                  !!data.length &&
                  data.map((ae, index) => (
                    <tr key={`ev-${index}`} className={index % 2 == 0 ? "" : "bg-gray-50"}>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                        {ae.patient.full_name.length > 30
                          ? `${ae.patient.full_name.slice(0, 30)}...`
                          : ae.patient.full_name}{" "}
                      </td>
                      <td className="text-center">
                        <a className="cursor-pointer" href={`mailto:${ae.patient.email}`}>
                          ✉️
                        </a>
                        <a
                          className="cursor-pointer ml-3"
                          href={`https://wa.me/${parsePhone(ae.patient.phone)}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          📱
                        </a>
                      </td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500 text-center">
                        {ae.contacted_after_bounce && (
                          <span
                            className="cursor-pointer"
                            onClick={async () => {
                              await postContactedAfterBounce(ae.id, false);
                              forceRefreshAppointmentsBounces();
                            }}
                          >
                            ✅
                          </span>
                        )}
                        {!ae.contacted_after_bounce && (
                          <span
                            className="cursor-pointer"
                            onClick={async () => {
                              await postContactedAfterBounce(ae.id, true);
                              forceRefreshAppointmentsBounces();
                            }}
                          >
                            ✖️
                          </span>
                        )}
                      </td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                        <a
                          className="text-blue-600"
                          href={`http://analytics.examedi.com.s3-website-us-east-1.amazonaws.com/appointment/${ae.id}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {moment(ae.created_at).format("HH:mm:ss D MMM")}
                        </a>
                      </td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                        {shortenServices(ae.service_names)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableBounces;
