import React from "react";

import SEO from "~/src/components/SEO";
import TableBounces from "~/src/components/TableBounces";
import TableEvents from "~/src/components/TableEvents";

import "react-day-picker/lib/style.css";

const REFRESH_APPOINTMENTS_EVENTS_INTERVAL = 60000; // ms
const REFRESH_APPOINTMENTS_BOUNCES_INTERVAL = 30000; // ms

function IndexPage(): JSX.Element {
  return (
    <>
      <SEO title="Examedi Marketing Dashboard" />
      <div className="min-h-screen w-full bg-gray-50 grid grid-cols-2 gap-4 p-4">
        <div className="col-span-2 xl:col-span-1">
          <TableBounces interval={REFRESH_APPOINTMENTS_BOUNCES_INTERVAL} tallerTable />
        </div>
        <div className="col-span-2 xl:col-span-1">
          <TableEvents interval={REFRESH_APPOINTMENTS_EVENTS_INTERVAL} tallerTable />
        </div>
      </div>
    </>
  );
}

export default IndexPage;
